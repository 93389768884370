const colors = ['cyan', 'fuchsia', 'yellow', 'lime'];

document.querySelectorAll('a').forEach(el => {
  el.addEventListener('mouseenter', () => {
    document.documentElement.style.setProperty('--border-color', colors[randomNum(0, colors.length -1)]);
  });
});

if (matchMedia('(pointer:fine)').matches) {
  let throttleTimer;
  const throttle = (callback, time) => {
    if (throttleTimer) return;

    throttleTimer = true;

    setTimeout(() => {
      callback();
      throttleTimer = false;
    }, time);
  };

  function randomNum(min, max) {
    return Math.floor(Math.random() * (max - min + 1) ) + min;
  }

  let mouseIn = false;

  const imagesEls = document.querySelectorAll('.hidden-images img');

  const showImage = (e) => {
    const x = event.clientX;
    const y = event.clientY;
    const maWorkImgEl = document.querySelector('.ma-work');

    maWorkImgEl.style.left = x+'px';
    maWorkImgEl.style.top = y+'px';

    throttle( () => {
      if(mouseIn) {
        const imgSrc = imagesEls[randomNum(0, imagesEls.length -1)].getAttribute('src');
        maWorkImgEl.innerHTML = `<img src="${imgSrc}">`;
      }
    }, 200);
  };

  document.querySelector('[data-ma-work]').addEventListener('mouseleave', () => {
    mouseIn = false;
    if(document.querySelector('.ma-work img'))
      document.querySelector('.ma-work img').remove();
  });
  document.querySelector('[data-ma-work]').addEventListener('mouseenter', () => {
    mouseIn = true;
  });
  document.querySelector('[data-ma-work]').addEventListener('mousemove', showImage);
}
